import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  filter,
  map,
  type Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type {
  TAttributeListRequest,
  TAttributeListResponse,
  TAttributeQueryField,
  TAttributeSortField,
} from '../types';
import { AttributeApiService } from '../services';
import { attributeFeature } from './attribute.reducer';
import { attributeActions } from './attribute.actions';

@Injectable({ providedIn: 'root' })
export class AttributeEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _attributeApiService: AttributeApiService,
  ) {}

  loadAttributes$ = createEffect(() =>
    this._fetchAttributes$(
      [
        attributeActions.setQueryData,
        attributeActions.resetQueryFilter,
        attributeActions.setQueryFilterDisabled,
      ],
      attributeActions.loadListSuccess,
      attributeActions.loadListFailure,
    ),
  );

  refreshAttributes$ = createEffect(() =>
    this._fetchAttributes$(
      [attributeActions.setPageSize, attributeActions.setPageIndex, attributeActions.setSort],
      attributeActions.refreshListSuccess,
      attributeActions.refreshListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(attributeActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(attributeFeature.selectPagination),
        this._store.select(attributeFeature.selectSort),
        this._store.select(attributeFeature.selectQuery),
        this._store.select(attributeFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._attributeApiService
          .getList$(
            projectId,
            createListPayload<TAttributeSortField, TAttributeQueryField, TAttributeListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => attributeActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(attributeActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => attributeActions.resetState()),
    ),
  );

  private _fetchAttributes$(
    actions: ActionCreator[],
    successCallback: (response: { result: TAttributeListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(attributeFeature.selectPagination),
        this._store.select(attributeFeature.selectSort),
        this._store.select(attributeFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._attributeApiService
          .getList$(
            projectId,
            createListPayload<TAttributeSortField, TAttributeQueryField, TAttributeListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
