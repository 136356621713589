import { createFeature, createReducer, on } from '@ngrx/store';

import type { TEmbeddedPlatformDataLoadingStatus } from '../types';
import { embeddedPlatformActions } from './embedded-platform.actions';

export type TEmbeddedPlatformState = {
  dataLoadingStatus: TEmbeddedPlatformDataLoadingStatus;
};

const defaultState: TEmbeddedPlatformState = {
  dataLoadingStatus: 'IDLE',
};

const initialState: TEmbeddedPlatformState = {
  ...defaultState,
};

const embeddedPlatformReducer = createReducer(
  initialState,
  on(embeddedPlatformActions.setDataLoadingStatus, (state, { status }) => ({
    ...state,
    dataLoadingStatus: status,
  })),
);

export const embeddedPlatformFeature = createFeature({
  name: 'embeddedPlatform',
  reducer: embeddedPlatformReducer,
});
