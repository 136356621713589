import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { ATTRIBUTE_STORE_OPTIONS } from './attribute-store-options.constant';
import type { TAttribute, TAttributeQueryField, TAttributeSortField } from '../types';

export const attributeActions = createActionGroup({
  source: 'attribute',
  events: {
    ...getEntityListActions<TAttribute, TAttributeQueryField, TAttributeSortField>()(
      ATTRIBUTE_STORE_OPTIONS,
    ),
  },
});
